import { useTranslation } from "react-i18next"
import { CopyrightsContent } from "components/content"
import Dialog from "../Dialog"

interface CopyrightsDialogProps {
    onAccept: () => unknown
}

function CopyrightsDialog({ onAccept }: CopyrightsDialogProps) {
    const { t } = useTranslation()
    return (
        <Dialog
            isShown
            textCenter
            title={t("content.copyrights.title")}
            onAccept={onAccept}
            onExit={onAccept}
        >
            <CopyrightsContent />
        </Dialog>
    )
}

export default CopyrightsDialog