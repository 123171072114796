import { useTranslation } from "react-i18next"
import { ChangelogContent } from "components/content"
import Dialog from "../Dialog"

interface ChangelogDialogProps {
    onAccept: () => unknown
    isShownAfterUpdate?: boolean
}

function ChangelogDialog({ onAccept, isShownAfterUpdate }: ChangelogDialogProps) {
    const { t } = useTranslation()
    return (
        <Dialog
            isShown
            textCenter
            title={t("content.changelog.title")}
            onAccept={onAccept}
            onExit={onAccept}
        >
            <ChangelogContent isShownAfterUpdate={isShownAfterUpdate} />
        </Dialog>
    )
}

export default ChangelogDialog