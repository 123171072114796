import { Dialog } from "components"
import { useTranslation } from "react-i18next"
import { isProbablyMobile } from "utils/utils"

import styles from "./ManualDialog.module.css"
import { useMemo } from "react"

interface ManualDialogProps {
    onClose: () => unknown
}

function ManualDialog({ onClose }: ManualDialogProps) {
    const isMobile = useMemo(() => isProbablyMobile(), [])
    const { t } = useTranslation()
    return (
        <Dialog
            isShown
            title={t("dialog.manual.title")}
            onExit={onClose}
            onAccept={onClose}
        >
            <section className={styles.root}>
                <h3>{t("dialog.manual.types.subtitle")}</h3>
                <p>
                    {t("dialog.manual.types.content-1")}
                    <br />
                    <img src={`${process.env.PUBLIC_URL}/assets/manual/togglemode.jpg`} alt={t("dialog.manual.types.alt")} />
                    {t("dialog.manual.types.content-2")}
                </p>
                <h3>{t("dialog.manual.offline.subtitle")}</h3>
                {isMobile && (
                    <p>
                        {t("dialog.manual.offline.content-mobile-1")}
                        <img src={`${process.env.PUBLIC_URL}/assets/manual/mobileinstall1.png`} alt={t("dialog.manual.offline.alt-mobile-install-1")} />
                        <img src={`${process.env.PUBLIC_URL}/assets/manual/mobileinstall2.jpg`} alt={t("dialog.manual.offline.alt-mobile-install-2")} />
                        {t("dialog.manual.offline.content-mobile-2")}
                        <img src={`${process.env.PUBLIC_URL}/assets/manual/desktopinstall.jpg`} alt={t("dialog.manual.offline.alt-desktop-install")} />
                    </p>
                )}
                {!isMobile && (
                    <p>
                        {t("dialog.manual.offline.content-desktop-1")}
                        <img src={`${process.env.PUBLIC_URL}/assets/manual/desktopinstall.jpg`} alt={t("dialog.manual.offline.alt-desktop-install")} />
                        {t("dialog.manual.offline.content-desktop-2")}
                        <img src={`${process.env.PUBLIC_URL}/assets/manual/mobileinstall1.png`} alt={t("dialog.manual.offline.alt-mobile-install-1")} />
                        <img src={`${process.env.PUBLIC_URL}/assets/manual/mobileinstall2.jpg`} alt={t("dialog.manual.offline.alt-mobile-install-2")} />
                    </p>
                )}
                <p>
                    {t("dialog.manual.reminder")}
                </p>
            </section>
        </Dialog>
    )
}

export default ManualDialog