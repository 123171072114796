import { DB } from "utils/constants";

export const createInitialDataInDbAndGetStatus = async (): Promise<void> => {
    if (!window.indexedDB) {
      Promise.reject();
    }
    const request: IDBOpenDBRequest = window.indexedDB.open(DB.name);
    let db: IDBDatabase; 
    request.onerror = () => {
      Promise.reject();
    }
    request.onsuccess = () => {
      Promise.resolve()
    }
    request.onupgradeneeded = (event) => {
      db = (event.target as IDBRequest).result;
      db.createObjectStore(DB.objectStoreName)
    }
}

export const saveSingleResourceToIndexedDb = (key: string, arrayBuffer: ArrayBuffer): Promise<void> => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(DB.name)
    request.addEventListener("success", (event) => {
      const database: IDBDatabase = (event.target as IDBRequest).result
      saveSingleResourceToSpecificDatabase(database, key, arrayBuffer).then(resolve).catch(reject)
    })
    request.addEventListener("error", reject)
    request.addEventListener("blocked", reject)
  })
}

export const saveSingleResourceToSpecificDatabase = (
  database: IDBDatabase,
  key: string,
  arrayBuffer: ArrayBuffer
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const transaction = database.transaction(DB.objectStoreName, "readwrite")
    const objectStore = transaction.objectStore(DB.objectStoreName)
    const checkIfResourceExists = objectStore.getKey(key)
    checkIfResourceExists.addEventListener("error", reject)
    checkIfResourceExists.addEventListener("success", event => {
      if ((event.target as IDBRequest<IDBValidKey | undefined>).result !== undefined) {
        return resolve()
      }
      const addResourceRequest = objectStore.add(arrayBuffer, key);
      addResourceRequest.addEventListener("success", () => resolve())
      addResourceRequest.addEventListener("error", reject)
    })
  })
}

export const countResourcesInDatabase = (): Promise<number> => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(DB.name)
    request.addEventListener("success", (event) => {
      const db: IDBDatabase = (event.target as IDBRequest).result
      const transaction = db.transaction(DB.objectStoreName, "readonly")
      const objectStore = transaction.objectStore(DB.objectStoreName)
      objectStore.count().addEventListener("success", (event) => {
        const savedSongs = (event.target as IDBRequest<number>).result;
        resolve(savedSongs)
      })
    })
    request.addEventListener("error", reject)
    request.addEventListener("blocked", reject)
  })
}

export const clearResourcesInObjectStoreDatabase = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(DB.name)
    request.addEventListener("success", (event) => {
      const db: IDBDatabase = (event.target as IDBRequest).result
      const transaction = db.transaction(DB.objectStoreName, "readwrite")
      const objectStore = transaction.objectStore(DB.objectStoreName)
      objectStore.clear().addEventListener("success", () => resolve())
    })
    request.addEventListener("error", reject)
    request.addEventListener("blocked", reject)
  })
}

export const getListOfResourcesSavedInDatabase = (): Promise<IDBValidKey[]> => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(DB.name)
    request.addEventListener("success", (event) => {
      const db: IDBDatabase = (event.target as IDBRequest).result
      const transaction = db.transaction(DB.objectStoreName, "readonly")
      const objectStore = transaction.objectStore(DB.objectStoreName)
      objectStore.getAllKeys().addEventListener("success", (event) => {
        const savedSongs = (event.target as IDBRequest<IDBValidKey[]>).result;
        resolve(savedSongs)
      })
    })
    request.addEventListener("error", reject)
    request.addEventListener("blocked", reject)
  })
}
