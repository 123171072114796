import { createPortal } from "react-dom"
import styles from "./Notification.module.css"

interface NotificationProps {
    children: string | JSX.Element | JSX.Element[]
}

const dialogRootElement = document.getElementById('dialog-root')!

function Notification({ children }: NotificationProps) {
    return createPortal(
        <section className={styles.root}>{children}</section>,
        dialogRootElement
    )
}

export default Notification