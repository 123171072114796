import { useEffect, useState } from "react"
import {
  Mode,
  LOCAL_STORAGE,
  SONG_NAMES_COMBAT,
  SONG_NAMES_EXPLORATION,
  INITIAL_EXPLORATION_SONG_INDEX,
  INITIAL_COMBAT_SONG_INDEX
} from "utils/constants"
import { getListOfResourcesSavedInDatabase } from "utils/indexedDb"
import useOnlineStatus from "hooks/useOnlineStatus"
import { shuffleArray } from "utils/utils"
import usePrevious from "./usePrevious"

export const usePlaylistManager = () => {
  const [musicMode, setMusicMode] = useState<Mode>(Mode.EXPLORATION)
  const [availableCombatIndexes, setAvailableCombatIndexes] = useState<number[]>([])
  const [availableExplorationIndexes, setAvailableExplorationIndexes] = useState<number[]>([])
  const [currentCombatSongIndex, setCurrentCombatSongIndex] = useState<number>(
    window.localStorage.getItem(LOCAL_STORAGE.combatSongIndex) ?
      Number(window.localStorage.getItem(LOCAL_STORAGE.combatSongIndex)) :
      INITIAL_COMBAT_SONG_INDEX
  )
  const [currentExplorationSongIndex, setCurrentExplorationSongIndex] = useState<number>(
    window.localStorage.getItem(LOCAL_STORAGE.explorationSongIndex) ?
      Number(window.localStorage.getItem(LOCAL_STORAGE.explorationSongIndex)) :
      INITIAL_EXPLORATION_SONG_INDEX
  )
  const isOnline = useOnlineStatus()
  const [isMusicRandomized, setIsMusicRandomized] = useState<boolean>(window.localStorage.getItem(LOCAL_STORAGE.playerSettings.isRandomized) === "true")
  const previousIsMusicRandomized = usePrevious<boolean>(isMusicRandomized)
  const toggleRandomMode = (): void => setIsMusicRandomized(prevState => !prevState)

  useEffect(() => {
    if (previousIsMusicRandomized === isMusicRandomized) {
      return
    }
    if (isOnline) {
      let newCombatIndexes = [...new Array(SONG_NAMES_COMBAT.length)].map((_, index) => index)
      let newExplorationIndexes = [...new Array(SONG_NAMES_EXPLORATION.length)].map((_, index) => index)

      if (isMusicRandomized) {
        newCombatIndexes = shuffleArray(newCombatIndexes, currentCombatSongIndex)
        newExplorationIndexes = shuffleArray(newExplorationIndexes, currentExplorationSongIndex)
      }

      setAvailableCombatIndexes(newCombatIndexes)
      setAvailableExplorationIndexes(newExplorationIndexes)
      return
    }
    getListOfResourcesSavedInDatabase()
      .then((savedSongs: IDBValidKey[]) => {
        let newCombatIndexes = savedSongs
          .map(name => SONG_NAMES_COMBAT.map(el => el.path).indexOf(name.toString()))
          .filter(index => index > -1)
        let newExplorationIndexes = savedSongs
          .map(name => SONG_NAMES_EXPLORATION.map(el => el.path).indexOf(name.toString()))
          .filter(index => index > -1)
        
        if (isMusicRandomized) {
          newCombatIndexes = shuffleArray(newCombatIndexes, currentCombatSongIndex)
          newExplorationIndexes = shuffleArray(newExplorationIndexes, currentExplorationSongIndex)
        }

        setAvailableCombatIndexes(newCombatIndexes)
        setAvailableExplorationIndexes(newExplorationIndexes)
      })
  }, [isOnline, previousIsMusicRandomized, isMusicRandomized, currentCombatSongIndex, currentExplorationSongIndex])

  const toggleMusicMode = (): void => setMusicMode(prevMode => prevMode === Mode.COMBAT ? Mode.EXPLORATION : Mode.COMBAT)

  const [isMusicRepeated, setIsMusicRepeated] = useState<boolean>(window.localStorage.getItem(LOCAL_STORAGE.playerSettings.isRepeated) === "true")
  const toggleRepeatMode = (): void => setIsMusicRepeated(prevState => !prevState)

  useEffect(() => {
    if (window.localStorage.getItem(LOCAL_STORAGE.explorationSongIndex)) {
      return
    }
    window.localStorage.setItem(LOCAL_STORAGE.combatSongIndex, INITIAL_COMBAT_SONG_INDEX.toString())
    window.localStorage.setItem(LOCAL_STORAGE.explorationSongIndex, INITIAL_EXPLORATION_SONG_INDEX.toString())
  }, [])

  useEffect(() => {
    window.localStorage.setItem(LOCAL_STORAGE.playerSettings.isRandomized, isMusicRandomized.toString())
  }, [isMusicRandomized])

  useEffect(() => {
    window.localStorage.setItem(LOCAL_STORAGE.playerSettings.isRepeated, isMusicRepeated.toString())
  }, [isMusicRepeated])

  useEffect(() => {
    window.localStorage.setItem(LOCAL_STORAGE.combatSongIndex, currentCombatSongIndex.toString())
  }, [currentCombatSongIndex])

  useEffect(() => {
    window.localStorage.setItem(LOCAL_STORAGE.explorationSongIndex, currentExplorationSongIndex.toString())
  }, [currentExplorationSongIndex])

  const playPreviousSong = (): void => {
    if (musicMode === Mode.COMBAT) {
      let newIndex = currentCombatSongIndex === 0 ? availableCombatIndexes.length - 1 : currentCombatSongIndex - 1
      setCurrentCombatSongIndex(newIndex)
    } else {
      let newIndex = currentExplorationSongIndex === 0 ? availableExplorationIndexes.length - 1 : currentExplorationSongIndex - 1
      setCurrentExplorationSongIndex(newIndex)
    }
  }

  const playNextSong = (): void => {
    if (musicMode === Mode.COMBAT) {
      let newIndex = currentCombatSongIndex >= availableCombatIndexes.length - 1 ? 0 : currentCombatSongIndex + 1
      setCurrentCombatSongIndex(newIndex)
    } else {
      let newIndex = currentExplorationSongIndex >= availableExplorationIndexes.length - 1 ? 0 : currentExplorationSongIndex + 1
      setCurrentExplorationSongIndex(newIndex)
    }
  }

  return {
    playPreviousSong,
    playNextSong,

    musicMode,
    toggleMusicMode,

    currentCombatSongIndex:
      availableCombatIndexes[currentCombatSongIndex] === undefined ?
      currentCombatSongIndex :
      availableCombatIndexes[currentCombatSongIndex],
    currentExplorationSongIndex:
      availableExplorationIndexes[currentExplorationSongIndex] === undefined ?
      currentExplorationSongIndex :
      availableExplorationIndexes[currentExplorationSongIndex],

    isMusicRandomized,
    toggleRandomMode,

    isMusicRepeated,
    toggleRepeatMode,
  }

}

export default usePlaylistManager