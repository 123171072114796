import { useEffect, useState } from 'react'
import { LOCAL_STORAGE } from 'utils/constants'
import { InitialView, PlayerView } from 'views'

function App() {
  const [wasInitialScreenIntroducted] = useState<boolean>(
    window.localStorage.getItem(LOCAL_STORAGE.initial.wasWelcomeDialogAccepted) === "true"
  )

  useEffect(() => {
    window.localStorage.setItem(LOCAL_STORAGE.initial.wasWelcomeDialogAccepted, wasInitialScreenIntroducted.toString())
  }, [wasInitialScreenIntroducted])

  useEffect(() => {
    const areTransitionEffectsSet = window.localStorage.getItem(LOCAL_STORAGE.playerSettings.areTransitionEffectsActive);
    if (areTransitionEffectsSet === null) {
      window.localStorage.setItem(LOCAL_STORAGE.playerSettings.areTransitionEffectsActive, true.toString())
    }
  }, [])

  const [isPlayerShown, setIsPlayerShown] = useState<boolean>(false)

  return isPlayerShown ? <PlayerView /> : <InitialView onPlayerStart={() => setIsPlayerShown(true)} />
}

export default App
