import { useTranslation } from "react-i18next"
import styles from "./CopyrightsContent.module.css"

interface CopyrightsContentProps {
    withTitle?: boolean
}

function CopyrightsContent({ withTitle }: CopyrightsContentProps) {
    const { t } = useTranslation()
    return (
        <section className={styles.root}>
            {withTitle && <h3>{t("content.copyrights.title")}</h3>}
            <p>{t("content.copyrights.content")}</p>
        </section>
    )
}

export default CopyrightsContent