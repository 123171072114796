import { MouseEvent } from "react"
import { AUDIO_CLICK_EFFECT_ID } from "utils/constants"
import styles from "./Button.module.css"

interface ButtonProps {
    title: string
    type: 'ACCEPT' | 'DENY' | 'QUESTION' | 'SQUARE' | 'PLAYER'
    className?: string
    children?: string | JSX.Element | JSX.Element[]
    onClick?: (event: MouseEvent<HTMLButtonElement>) => unknown 
    withStrikeIcon?: boolean
    disabled?: boolean
}

function Button({ onClick, children, type, title, className = '', disabled, withStrikeIcon}: ButtonProps) {
    const audio = document.getElementById(AUDIO_CLICK_EFFECT_ID) as HTMLAudioElement

    const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
        if (disabled) {
            return
        }
        onClick?.(event)
        audio.play()
    }

    if (children) {
        return (
            <button
                onClick={(event) => handleClick(event)}
                disabled={disabled}
                title={title}
                type="button"
                className={`${styles.root} ${styles[type.toLowerCase()]} ${className}`}
            >{children}{withStrikeIcon && (<img src={`${process.env.PUBLIC_URL}/assets/player/strike.png`} alt="" />)}</button>
        )
    }

    return (
        <button
            onClick={(event) => handleClick(event)}
            disabled={disabled}
            title={title}
            type="button"
            className={`${styles.root} ${styles[type.toLowerCase()]} ${className}`}
        />
    )
}

export default Button