import { useTranslation } from "react-i18next"
import styles from "./ChangelogContent.module.css"
import { ChangelogEntry } from "utils/constants"

interface ChangelogContentProps {
    withTitle?: boolean
    isShownAfterUpdate?: boolean
}

function ChangelogContent({ withTitle, isShownAfterUpdate }: ChangelogContentProps) {
    const { t } = useTranslation()
    const listOfChanges = t("content.changelog.history", { returnObjects: true }) as ChangelogEntry[]
    return (
        <section className={styles.root}>
            {withTitle && <h3>{t("content.changelog.title")}</h3>}
            {isShownAfterUpdate && <p>{t("content.changelog.update")}</p>}
            {Array.isArray(listOfChanges) && listOfChanges.length > 0 && (
                <ul>
                    {listOfChanges.map(({ version, date, features }) => (
                        <li key={version}>
                            {version} ({date})
                            <ul>
                                {features.map(singleFeature => (
                                    <li key={singleFeature}>{singleFeature}</li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            )}
        </section>
    )
}

export default ChangelogContent