import { useTranslation } from "react-i18next"
import Dialog from "../Dialog"

interface InteractionDialogProps {
    onAccept: () => unknown
}

function InteractionDialog({ onAccept }: InteractionDialogProps) {
    const { t } = useTranslation()
    return (
        <Dialog
            isShown
            textCenter
            title={t("dialog.interaction.title")}
            onAccept={onAccept}
        >
            <section className="interactionDialog">
                {t("dialog.interaction.content")}
                <img src={`${process.env.PUBLIC_URL}/assets/horn.png`} alt={t("dialog.interaction.alt")} />
            </section>
        </Dialog>
    )
}

export default InteractionDialog