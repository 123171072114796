import { useTranslation } from "react-i18next"
import { AUDIO_CLICK_EFFECT_ID } from "utils/constants"
import styles from "./SettingsDialogButton.module.css"

interface SettingsDialogButtonProps {
    onClick: () => unknown
}

function SettingsDialogButton({ onClick }: SettingsDialogButtonProps) {
    const { t } = useTranslation()
    const audio = document.getElementById(AUDIO_CLICK_EFFECT_ID) as HTMLAudioElement

    return (
        <button
            title={t("dialog.settings.title")}
            type="button"
            className={styles.root}
            onClick={() => {
                audio.play()
                onClick()
            }}>
            <img
                src={`${process.env.PUBLIC_URL}/assets/player/settings.png`}
                alt={t("dialog.settings.title")}
            />
        </button>
    )
}

export default SettingsDialogButton
